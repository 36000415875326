import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import UnproductiveTable from "./TeamManagement/UnproductivityJustification/UnproductiveTable";
import { getAlertUnproductivityJustificationTable, setResetAssessUnproductivityJustificationStatus, setResetJustificationStatus, unproductivityJustificationSelector } from "../../../../features/senninha/GestaoEquipe/unproductivityJustificationSlice";

import * as S from "./styles";


const NewSenninha = () => {

    const {
        unproductivityJustificationData,
    } = useSelector(unproductivityJustificationSelector);

    return (
        <>
            {unproductivityJustificationData?.length !== 0 && (
                <S.AllPageAlertSenninhaTeam>
                    <UnproductiveTable senninhaScreen />
                </S.AllPageAlertSenninhaTeam>
            )}
        </>
    );
};

export default NewSenninha;
