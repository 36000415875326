import styled from "styled-components";


export const AllPageAlertSenninhaTeam = styled.div`
    background: rgb(52 48 66 / 57%);
    position: absolute;
    z-index: 100;
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
`;

