/* eslint-disable no-plusplus */
/* eslint-disable no-mixed-operators */
/* eslint-disable no-bitwise */
export default function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function toCapitalize(str = "") {
  return str.charAt(0).toUpperCase() + str.toLowerCase().slice(1);
}

export function toCapitalizeFirstLetterOfEachWord(str = "") {
  const arr = str.split(" ");

  for (let i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].toLowerCase().slice(1);
  }

  return arr.join(" ");
}

export const compareTableColumn = (a, b, key) =>
  `${a[key]}`.localeCompare(b[key]);

export const senninhaColors = [
  "#FF0592",
  "#E35947",
  "#A64343",
  "#B219FA",
  "#603EA8",
  "#1B4B36",
  "#0C9294",
  "#295090",
  "#CDAE40",
  "#1AD0BE",
  "#FF69B4",
  "#FF7F73",
  "#D19178",
  "#D489CC",
  "#9B77DA",
  "#538F6A",
  "#6FA5B1",
  "#4682B4",
  "#FFC700",
  "#53EBDC",
  "#FFC5CE",
  "#FFAC9A",
  "#F0C7A9",
  "#FEB6F6",
  "#9B77DA",
  "#AEBD77",
  "#B6D4DB",
  "#93CDFD",
  "#CBC9C2",
  "#9ED7D2"
];

export const uuidToShortString = async (uuid, subStrNumber=4) => {
  const encoder = new TextEncoder();
  const data = encoder.encode(uuid);
  const hashBuffer = await crypto.subtle.digest('SHA-256', data);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  
  const hashHex = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');

  const shortString = hashHex.substring(0, subStrNumber);

  return shortString;
}

export function downloadBase64PDF(base64String) {
  const byteCharacters = atob(base64String);
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: 'application/pdf' });
  const url = URL.createObjectURL(blob);

  window.open(url, '_blank');
}

// emails para dar permissao de regional na gestao de equipe
export const regionalEmails = [
    //devs
    "mariana.brito@stone.com.br",
    "eduardo.torres@stone.com.br",
    "rair.suzuki@stone.com.br",
    //product 
    "boneve@stone.com.br",
    "alyson.ferreira@stone.com.br",
    //regionals 
    "amauri.ferreira@stone.com.br",
    "douglas.sardinha@stone.com.br",
    "gcalves@stone.com.br",
    "levedove@stone.com.br",
    "lucas.rodrigues@stone.com.br",
    "ncabral@stone.com.br",
]

export const optionsJustifyManagementTeam = [
    {
      value: "Compensação de feriado local",
      label: "Compensação de feriado local",
    },
    {
      value: "Deslocamento viagem",
      label: "Deslocamento viagem",
    },
    {
      value: "Eventos internos",
      label: "Eventos internos",
    },
    {
      value: "Envio de delivery",
      label: "Envio de delivery",
    },
    {
      value: "Outros trabalhos interno",
      label: "Outros trabalhos interno",
    },
    {
      value: "Rota sombra especialista",
      label: "Rota sombra especialista",
    },
    {
      value: "Onboarding",
      label: "Onboarding",
    },
    {
      value: "Escala de sábado",
      label: "Escala de sábado",
    },
    {
      value: "Folga combinada",
      label: "Folga combinada",
    },
    {
      value: "Atestado médico",
      label: "Atestado médico",
    },
    {
      value: "Falta não justificada",
      label: "Falta não justificada",
    },
]